@import url(fontawesome-all.min.css);
@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400';

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

	blockquote:before, blockquote:after, q:before, q:after {
		content: '';
		content: none;
	}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	-webkit-text-size-adjust: none;
}

mark {
	background-color: transparent;
	color: inherit;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input, select, textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
}

/* Basic */

	@-ms-viewport {
		width: device-width;
	}

	body {
		-ms-overflow-style: scrollbar;
	}

	@media screen and (max-width: 480px) {

		html, body {
			min-width: 320px;
		}

	}

	html {
		box-sizing: border-box;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	body {
		background-color: #fceb5f;
		background-image: url("images/overlay.png"), -moz-linear-gradient(45deg, #fceb5f 15%, #fab8c1 85%);
		background-image: url("images/overlay.png"), -webkit-linear-gradient(45deg, #fceb5f 15%, #fab8c1 85%);
		background-image: url("images/overlay.png"), -ms-linear-gradient(45deg, #fceb5f 15%, #fab8c1 85%);
		background-image: url("images/overlay.png"), linear-gradient(45deg, #fceb5f 15%, #fab8c1 85%);
	}

		body.is-preload *, body.is-preload *:before, body.is-preload *:after {
			-moz-animation: none !important;
			-webkit-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
			-moz-transition: none !important;
			-webkit-transition: none !important;
			-ms-transition: none !important;
			transition: none !important;
		}

/* Type */

	body {
		background-color: #fceb5f;
		color: rgba(255, 255, 255, 0.65);
	}

	body, input, select, textarea {
		font-family: "Source Sans Pro", Helvetica, sans-serif;
		font-size: 12pt;
		font-weight: 300;
		line-height: 1.65;
	}

		@media screen and (max-width: 1680px) {

			body, input, select, textarea {
				font-size: 12pt;
			}

		}



	a {
		-moz-transition: color 0.2s ease, border-bottom 0.2s ease;
		-webkit-transition: color 0.2s ease, border-bottom 0.2s ease;
		-ms-transition: color 0.2s ease, border-bottom 0.2s ease;
		transition: color 0.2s ease, border-bottom 0.2s ease;
		text-decoration: none;
		border-bottom: dotted 1px;
		color: inherit;
	}

		a:hover {
			border-bottom-color: transparent;
		}

	strong, b {
		font-weight: 400;
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 2em 0;
	}

		p.content {
			-moz-columns: 20em 2;
			-webkit-columns: 20em 2;
			-ms-columns: 20em 2;
			columns: 20em 2;
			-moz-column-gap: 2em;
			-webkit-column-gap: 2em;
			-ms-column-gap: 2em;
			column-gap: 2em;
			text-align: justify;
		}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 300;
		line-height: 1.5;
		margin: 0 0 0.7em 0;
		letter-spacing: -0.025em;
	}

		h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
			color: inherit;
			text-decoration: none;
		}

	h1 {
		font-size: 2.5em;
		line-height: 1.2;
	}

	h2 {
		font-size: 1.5em;
	}

	h3 {
		font-size: 1.25em;
	}

	h4 {
		font-size: 1.1em;
	}

	h5 {
		font-size: 0.9em;
	}

	h6 {
		font-size: 0.7em;
	}



	input, select, textarea {
		color: #ffffff;
	}

	a:hover {
		color: #ffffff;
	}

	strong, b {
		color: #ffffff;
	}

	h1, h2, h3, h4, h5, h6 {
		color: #ffffff;
	}

/* Row */

	.row {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		align-items: stretch;
	}

		.row > * {
			box-sizing: border-box;
		}

		.row.gtr-uniform > * > :last-child {
			margin-bottom: 0;
		}

		.row.aln-left {
			justify-content: flex-start;
		}

		.row.aln-center {
			justify-content: center;
		}

		.row.aln-right {
			justify-content: flex-end;
		}

		.row.aln-top {
			align-items: flex-start;
		}

		.row.aln-middle {
			align-items: center;
		}

		.row.aln-bottom {
			align-items: flex-end;
		}

		.row > .imp {
			order: -1;
		}

		.row > .col-1 {
			width: 8.33333%;
		}

		.row > .off-1 {
			margin-left: 8.33333%;
		}

		.row > .col-2 {
			width: 16.66667%;
		}

		.row > .off-2 {
			margin-left: 16.66667%;
		}

		.row > .col-3 {
			width: 25%;
		}

		.row > .off-3 {
			margin-left: 25%;
		}

		.row > .col-4 {
			width: 33.33333%;
		}

		.row > .off-4 {
			margin-left: 33.33333%;
		}

		.row > .col-5 {
			width: 41.66667%;
		}

		.row > .off-5 {
			margin-left: 41.66667%;
		}

		.row > .col-6 {
			width: 50%;
		}

		.row > .off-6 {
			margin-left: 50%;
		}

		.row > .col-7 {
			width: 58.33333%;
		}

		.row > .off-7 {
			margin-left: 58.33333%;
		}

		.row > .col-8 {
			width: 66.66667%;
		}

		.row > .off-8 {
			margin-left: 66.66667%;
		}

		.row > .col-9 {
			width: 75%;
		}

		.row > .off-9 {
			margin-left: 75%;
		}

		.row > .col-10 {
			width: 83.33333%;
		}

		.row > .off-10 {
			margin-left: 83.33333%;
		}

		.row > .col-11 {
			width: 91.66667%;
		}

		.row > .off-11 {
			margin-left: 91.66667%;
		}

		.row > .col-12 {
			width: 100%;
		}

		.row > .off-12 {
			margin-left: 100%;
		}

		.row.gtr-0 {
			margin-top: 0;
			margin-left: 0em;
		}

			.row.gtr-0 > * {
				padding: 0 0 0 0em;
			}

			.row.gtr-0.gtr-uniform {
				margin-top: 0em;
			}

				.row.gtr-0.gtr-uniform > * {
					padding-top: 0em;
				}

		.row.gtr-25 {
			margin-top: 0;
			margin-left: -0.375em;
		}

			.row.gtr-25 > * {
				padding: 0 0 0 0.375em;
			}

			.row.gtr-25.gtr-uniform {
				margin-top: -0.375em;
			}

				.row.gtr-25.gtr-uniform > * {
					padding-top: 0.375em;
				}

		.row.gtr-50 {
			margin-top: 0;
			margin-left: -0.75em;
		}

			.row.gtr-50 > * {
				padding: 0 0 0 0.75em;
			}

			.row.gtr-50.gtr-uniform {
				margin-top: -0.75em;
			}

				.row.gtr-50.gtr-uniform > * {
					padding-top: 0.75em;
				}

		.row {
			margin-top: 0;
			margin-left: -1.5em;
		}

			.row > * {
				padding: 0 0 0 1.5em;
			}

			.row.gtr-uniform {
				margin-top: -1.5em;
			}

				.row.gtr-uniform > * {
					padding-top: 1.5em;
				}

		.row.gtr-150 {
			margin-top: 0;
			margin-left: -2.25em;
		}

			.row.gtr-150 > * {
				padding: 0 0 0 2.25em;
			}

			.row.gtr-150.gtr-uniform {
				margin-top: -2.25em;
			}

				.row.gtr-150.gtr-uniform > * {
					padding-top: 2.25em;
				}

		.row.gtr-200 {
			margin-top: 0;
			margin-left: -3em;
		}

			.row.gtr-200 > * {
				padding: 0 0 0 3em;
			}

			.row.gtr-200.gtr-uniform {
				margin-top: -3em;
			}

				.row.gtr-200.gtr-uniform > * {
					padding-top: 3em;
				}

		@media screen and (max-width: 1680px) {

			.row {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;
				align-items: stretch;
			}

				.row > * {
					box-sizing: border-box;
				}

				.row.gtr-uniform > * > :last-child {
					margin-bottom: 0;
				}

				.row.aln-left {
					justify-content: flex-start;
				}

				.row.aln-center {
					justify-content: center;
				}

				.row.aln-right {
					justify-content: flex-end;
				}

				.row.aln-top {
					align-items: flex-start;
				}

				.row.aln-middle {
					align-items: center;
				}

				.row.aln-bottom {
					align-items: flex-end;
				}

				.row > .imp-xlarge {
					order: -1;
				}

				.row > .col-1-xlarge {
					width: 8.33333%;
				}

				.row > .off-1-xlarge {
					margin-left: 8.33333%;
				}

				.row > .col-2-xlarge {
					width: 16.66667%;
				}

				.row > .off-2-xlarge {
					margin-left: 16.66667%;
				}

				.row > .col-3-xlarge {
					width: 25%;
				}

				.row > .off-3-xlarge {
					margin-left: 25%;
				}

				.row > .col-4-xlarge {
					width: 33.33333%;
				}

				.row > .off-4-xlarge {
					margin-left: 33.33333%;
				}

				.row > .col-5-xlarge {
					width: 41.66667%;
				}

				.row > .off-5-xlarge {
					margin-left: 41.66667%;
				}

				.row > .col-6-xlarge {
					width: 50%;
				}

				.row > .off-6-xlarge {
					margin-left: 50%;
				}

				.row > .col-7-xlarge {
					width: 58.33333%;
				}

				.row > .off-7-xlarge {
					margin-left: 58.33333%;
				}

				.row > .col-8-xlarge {
					width: 66.66667%;
				}

				.row > .off-8-xlarge {
					margin-left: 66.66667%;
				}

				.row > .col-9-xlarge {
					width: 75%;
				}

				.row > .off-9-xlarge {
					margin-left: 75%;
				}

				.row > .col-10-xlarge {
					width: 83.33333%;
				}

				.row > .off-10-xlarge {
					margin-left: 83.33333%;
				}

				.row > .col-11-xlarge {
					width: 91.66667%;
				}

				.row > .off-11-xlarge {
					margin-left: 91.66667%;
				}

				.row > .col-12-xlarge {
					width: 100%;
				}

				.row > .off-12-xlarge {
					margin-left: 100%;
				}

				.row.gtr-0 {
					margin-top: 0;
					margin-left: 0em;
				}

					.row.gtr-0 > * {
						padding: 0 0 0 0em;
					}

					.row.gtr-0.gtr-uniform {
						margin-top: 0em;
					}

						.row.gtr-0.gtr-uniform > * {
							padding-top: 0em;
						}

				.row.gtr-25 {
					margin-top: 0;
					margin-left: -0.375em;
				}

					.row.gtr-25 > * {
						padding: 0 0 0 0.375em;
					}

					.row.gtr-25.gtr-uniform {
						margin-top: -0.375em;
					}

						.row.gtr-25.gtr-uniform > * {
							padding-top: 0.375em;
						}

				.row.gtr-50 {
					margin-top: 0;
					margin-left: -0.75em;
				}

					.row.gtr-50 > * {
						padding: 0 0 0 0.75em;
					}

					.row.gtr-50.gtr-uniform {
						margin-top: -0.75em;
					}

						.row.gtr-50.gtr-uniform > * {
							padding-top: 0.75em;
						}

				.row {
					margin-top: 0;
					margin-left: -1.5em;
				}

					.row > * {
						padding: 0 0 0 1.5em;
					}

					.row.gtr-uniform {
						margin-top: -1.5em;
					}

						.row.gtr-uniform > * {
							padding-top: 1.5em;
						}

				.row.gtr-150 {
					margin-top: 0;
					margin-left: -2.25em;
				}

					.row.gtr-150 > * {
						padding: 0 0 0 2.25em;
					}

					.row.gtr-150.gtr-uniform {
						margin-top: -2.25em;
					}

						.row.gtr-150.gtr-uniform > * {
							padding-top: 2.25em;
						}

				.row.gtr-200 {
					margin-top: 0;
					margin-left: -3em;
				}

					.row.gtr-200 > * {
						padding: 0 0 0 3em;
					}

					.row.gtr-200.gtr-uniform {
						margin-top: -3em;
					}

						.row.gtr-200.gtr-uniform > * {
							padding-top: 3em;
						}

		}

/* Button */

	.button-success {
		background-color: green;
		color: white;
	}

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		-moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		border-radius: 8px;
		border: 0;
		cursor: pointer;
		display: inline-block;
		font-weight: 500;
		height: 3.25em;
		line-height: 3.25em;
		min-width: 9.25em;
		padding: 0 1.5em;
		text-align: center;
		text-decoration: none;
		white-space: nowrap;
	}


	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		background-color: transparent;
		box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.35);
		color: #ffffff !important;
	}

		input[type="submit"]:hover,
		input[type="reset"]:hover,
		input[type="button"]:hover,
		button:hover,
		.button:hover {
			background-color: rgba(255, 255, 255, 0.075);
		}

		input[type="submit"].primary,
		input[type="reset"].primary,
		input[type="button"].primary,
		button.primary,
		.button.primary {
			background-color: #8cc9f0;
			color: #ffffff !important;
			box-shadow: none;
		}

/* Form */

	form {
		margin: 0 0 0 0;
	}

	label {
		display: block;
		font-size: 0.9em;
		font-weight: 400;
		margin: 0 0 1em 0;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="number"],

	select,
	textarea {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		border-radius: 8px;
		border: solid 1px;
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 1em; /* 0 1em */
		text-decoration: none;
		width: 100%;
		max-height: 2.75em;
		min-height: 2.75em;
	}

		input[type="text"]:invalid,
		input[type="password"]:invalid,
		input[type="email"]:invalid,
		select:invalid,
		textarea:invalid {
			box-shadow: none;
		}

	select {
		background-size: 1.25rem;
		background-repeat: no-repeat;
		background-position: calc(100% - 1rem) center;
		height: 2.75em;
		padding-right: 2.75em;
		text-overflow: ellipsis;
	}

		select:focus::-ms-value {
			background-color: transparent;
		}

		select::-ms-expand {
			display: none;
		}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		height: 2.75em;
	}

	textarea {
		padding: 0.75em 1em;
	}

	input[type="checkbox"],
	input[type="radio"] {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		display: block;
		float: left;
		margin-right: -2em;
		opacity: 0;
		width: 1em;
		z-index: -1;
	}

		input[type="checkbox"] + label,
		input[type="radio"] + label {
			text-decoration: none;
			cursor: pointer;
			display: inline-block;
			font-size: 1em;
			font-weight: 300;
			padding-left: 2.4em;
			padding-right: 0.75em;
			position: relative;
		}

			input[type="checkbox"] + label:before,
			input[type="radio"] + label:before {
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				text-transform: none !important;
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
			}

			input[type="checkbox"] + label:before,
			input[type="radio"] + label:before {
				border-radius: 8px;
				border: solid 1px;
				content: '';
				display: inline-block;
				font-size: 0.8em;
				height: 2.0625em;
				left: 0;
				line-height: 2.0625em;
				position: absolute;
				text-align: center;
				top: 0;
				width: 2.0625em;
			}

		input[type="checkbox"]:checked + label:before,
		input[type="radio"]:checked + label:before {
			content: '\f00c';
		}

	input[type="checkbox"] + label:before {
		border-radius: 8px;
	}

	input[type="radio"] + label:before {
		border-radius: 100%;
	}

	::-webkit-input-placeholder {
		opacity: 1.0;
	}

	:-moz-placeholder {
		opacity: 1.0;
	}

	::-moz-placeholder {
		opacity: 1.0;
	}

	:-ms-input-placeholder {
		opacity: 1.0;
	}

	label {
		color: #ffffff;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="number"],
	select,
	textarea {
		background-color: rgba(255, 255, 255, 0.075);
		border-color: rgba(255, 255, 255, 0.35);
	}

		input[type="text"]:focus,
		input[type="password"]:focus,
		input[type="email"]:focus,
		select:focus,
		textarea:focus {
			border-color: #8cc9f0;
			box-shadow: 0 0 0 1px #8cc9f0;
		}

	select {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(255, 255, 255, 0.35)' /%3E%3C/svg%3E");
	}

		select option {
			color: #ffffff;
			background: #fceb5f;
		}

	input[type="checkbox"] + label,
	input[type="radio"] + label {
		color: rgba(255, 255, 255, 0.65);
	}

		input[type="checkbox"] + label:before,
		input[type="radio"] + label:before {
			background: rgba(255, 255, 255, 0.075);
			border-color: rgba(255, 255, 255, 0.35);
		}

	input[type="checkbox"]:checked + label:before,
	input[type="radio"]:checked + label:before {
		background-color: #ffffff;
		border-color: #ffffff;
		color: #fceb5f;
	}

	input[type="checkbox"]:focus + label:before,
	input[type="radio"]:focus + label:before {
		border-color: #8cc9f0;
		box-shadow: 0 0 0 1px #8cc9f0;
	}

	::-webkit-input-placeholder {
		color: rgba(255, 255, 255, 0.5) !important;
	}

	:-moz-placeholder {
		color: rgba(255, 255, 255, 0.5) !important;
	}

	::-moz-placeholder {
		color: rgba(255, 255, 255, 0.5) !important;
	}

	:-ms-input-placeholder {
		color: rgba(255, 255, 255, 0.5) !important;
	}

	.formerize-placeholder {
		color: rgba(255, 255, 255, 0.5) !important;
	}
/* Icon */

	.icon {
		text-decoration: none;
		-moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		-ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
		border-bottom: none;
		position: relative;
	}

		.icon:before {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;
			text-transform: none !important;
			font-family: 'Font Awesome 5 Free';
			font-weight: 400;
		}

		.icon > .label {
			display: none;
		}

		.icon:before {
			line-height: solid;
		}

		.icon.solid:before {
			font-weight: 900;
		}

		.icon.brands:before {
			font-family: 'Font Awesome 5 Brands';
			color: #808080;
		}

		.icon.major {
			border: solid 1px;
			display: inline-block;
			border-radius: 100%;
			padding: 0.65em;
			margin: 0 0 2em 0;
			cursor: default;
		}

			.icon.major:before {
				display: inline-block;
				font-size: 6.25rem;
				width: 2.25em;
				height: 2.25em;
				line-height: 2.2em;
				border-radius: 100%;
				border: solid 1px;
				text-align: center;
			}

		.icon.alt {
			display: inline-block;
			border: solid 1px;
			border-radius: 100%;
		}

			.icon.alt:before {
				display: block;
				font-size: 1.25em;
				width: 2em;
				height: 2em;
				text-align: center;
				line-height: 2em;
			}


	.icon.alt {
		border-color: rgba(255, 255, 255, 0.35);
		color: #ffffff;
	}

		.icon.alt:hover {
			background-color: rgba(255, 255, 255, 0.075);
		}

		.icon.alt:active {
			background-color: rgba(255, 255, 255, 0.2);
		}

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 2em 0;
		padding-left: 1.25em;
	}

		ol li {
			padding-left: 0.25em;
		}

	ul {
		list-style: disc;
		margin: 0 0 2em 0;
		padding-left: 1em;
	}

		ul li {
			padding-left: 0.5em;
		}

/* Icons */

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;
	}

		ul.icons li {
			display: inline-block;
			padding: 0 0.65em 0 0;
		}

			ul.icons li:last-child {
				padding-right: 0 !important;
				color: #808080;
			}

/* Section/Article */

	section.special, article.special {
		text-align: center;
	}

	header.major {
		margin-bottom: 3em;
	}

		header.major h2 {
			font-size: 2em;
		}

			header.major h2:after {
				display: block;
				content: '';
				width: 3.25em;
				height: 2px;
				margin: 0.7em 0 1em 0;
				border-radius: 2px;
			}

				section.special header.major h2:after, article.special header.major h2:after {
					margin-left: auto;
					margin-right: auto;
				}

		header.major p {
			font-size: 1.25em;
			letter-spacing: -0.025em;
		}

		header.major.special {
			text-align: center;
		}

			header.major.special h2:after {
				margin-left: auto;
				margin-right: auto;
			}

	footer.major {
		margin-top: 3em;
	}

	header.major h2:after {
		background-color: rgba(255, 255, 255, 0.35);
	}



/* Statistics2 */

	.statistics2 {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		width: 95%;
		margin: 0 auto 3em auto;
		padding: 0;
		list-style: none;
		cursor: default;
		align: center;
	}

		.statistics2 li {
			-moz-flex: 1;
			-webkit-flex: 1;
			-ms-flex: 1;
			flex: 1;
			padding: 1.0em;
			color: #ffffff;
			text-align: center;
		}

			.statistics2 li.style1 {
				background-color: #efa8b0;
			}

			.statistics2 li.style2 {
				background-color: #c79cc8;
			}

			.statistics2 li.style3 {
				background-color: #a89cc8;
			}

			.statistics2 li.style4 {
				background-color: #9bb2e1;
			}

			.statistics2 li.style5 {
				background-color: #8cc9f0;
			}

			.statistics2 li strong, .statistics li b {
				display: block;
				font-size: 2em;
				line-height: 1.1;
				/* color: inherit !important; */
				font-weight: 700;
				letter-spacing: -0.025em;
			}

			.statistics2 li:first-child {
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
			}

			.statistics2 li:last-child {
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
			}

			.statistics2 li .icon {
				display: inline-block;
			}

				.statistics2 li .icon:before {
					font-size: 2.75rem;
					line-height: 1.3;
				}

		@media screen and (max-width: 980px) {

			.statistics2 li strong, .statistics li b {
				font-size: 1.5em;
			}

		}

		@media screen and (max-width: 736px) {

			.statistics2 {
				display: block;
				width: 20em;
				max-width: 100%;
				margin: 0 auto 0 auto;
			}

				.statistics2 li:first-child {
					border-bottom-left-radius: 0;
					border-top-right-radius: 8px;
				}

				.statistics2 li:last-child {
					border-top-right-radius: 0;
					border-bottom-left-radius: 8px;
				}

				.statistics2 li .icon:before {
					font-size: 3.75rem;
				}

				.statistics2 li strong, .statistics li b {
					font-size: 2.5em;
				}

		}

	.statistics {
		display: flex;
		width: 75%;
		margin: 1em auto;
		padding: 0;
		list-style: none;
		cursor: default;
		align-items: center;
		justify-content: center;
	}

		.statistics li {
			-moz-flex: 1;
			-webkit-flex: 1;
			-ms-flex: 1;
			flex: 1;
			padding: 1.0em;
			color: #ffffff;
			text-align: center;
		}

			.statistics li.style1 {
				background-color: #efa8b0;
			}

			.statistics li.style2 {
				background-color: #c79cc8;
			}

			.statistics li.style3 {
				background-color: #a89cc8;
			}

			.statistics li.style4 {
				background-color: #9bb2e1;
			}

			.statistics li.style5 {
				background-color: #8cc9f0;
			}

			.statistics li strong, .statistics li b {
				display: block;
				font-size: 2em;
				line-height: 1.1;
				/* color: inherit !important; */
				font-weight: 700;
				letter-spacing: -0.025em;
			}

			.statistics li:first-child {
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
			}

			.statistics li:last-child {
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
			}

			.statistics li .icon {
				display: inline-block;
			}

				.statistics li .icon:before {
					font-size: 2.75rem;
					line-height: 1.3;
				}

/* Header */

	#header {
		padding: 5em 5em 1em 5em ;
		text-align: center;
	}

		#header h1 {
			margin: 0 0 0.25em 0;
		}

		#header p {
			font-size: 1.25em;
			letter-spacing: -0.025em;
		}

		#header.alt {
			padding: 7em 5em 4em 5em ;
		}

			#header.alt h1 {
				font-size: 3.25em;
			}

			#header.alt > * {
				-moz-transition: opacity 3s ease;
				-webkit-transition: opacity 3s ease;
				-ms-transition: opacity 3s ease;
				transition: opacity 3s ease;
				-moz-transition-delay: 0.5s;
				-webkit-transition-delay: 0.5s;
				-ms-transition-delay: 0.5s;
				transition-delay: 0.5s;
				opacity: 1;
			}

			#header.alt .logo {
				-moz-transition: opacity 1.25s ease, -moz-transform 0.5s ease;
				-webkit-transition: opacity 1.25s ease, -webkit-transform 0.5s ease;
				-ms-transition: opacity 1.25s ease, -ms-transform 0.5s ease;
				transition: opacity 1.25s ease, transform 0.5s ease;
				-moz-transition-delay: 0s;
				-webkit-transition-delay: 0s;
				-ms-transition-delay: 0s;
				transition-delay: 0s;
				display: block;
				margin: 0 0 1.5em 0;
			}

				#header.alt .logo img {
					display: block;
					margin: 0 auto;
					max-width: 75%;
				}


		body.is-preload #header.alt > * {
			opacity: 0;
		}

		body.is-preload #header.alt .logo {
			-moz-transform: scale(0.8) rotate(-30deg);
			-webkit-transform: scale(0.8) rotate(-30deg);
			-ms-transform: scale(0.8) rotate(-30deg);
			transform: scale(0.8) rotate(-30deg);
		}

/* Nav */
#nav {
	-moz-transition: background-color 0.2s ease, border-top-left-radius 0.2s ease, border-top-right-radius 0.2s ease, padding 0.2s ease;
	-webkit-transition: background-color 0.2s ease, border-top-left-radius 0.2s ease, border-top-right-radius 0.2s ease, padding 0.2s ease;
	-ms-transition: background-color 0.2s ease, border-top-left-radius 0.2s ease, border-top-right-radius 0.2s ease, padding 0.2s ease;
	transition: background-color 0.2s ease, border-top-left-radius 0.2s ease, border-top-right-radius 0.2s ease, padding 0.2s ease;
	background-color: #ffffff;
	color: #636363;
	position: absolute;
	width: 94em;
	max-width: calc(100% - 4em);
	padding: 1em;
	background-color: #f7f7f7;
	border-top-left-radius: 0.25em;
	border-top-right-radius: 0.25em;
	cursor: default;
	text-align: center;
}

#nav input, #nav select, #nav textarea {
	color: #636363;
}

#nav a:hover {
	color: #636363;
}

#nav strong, #nav b {
	color: #636363;
}

#nav h1, #nav h2, #nav h3, #nav h4, #nav h5, #nav h6 {
	color: #636363;
}

#nav blockquote {
	border-left-color: #dddddd;
}

#nav code {
	background: rgba(222, 222, 222, 0.25);
	border-color: #dddddd;
}

#nav hr {
	border-bottom-color: #dddddd;
}

#nav + #main {
	padding-top: 4.25em;
}

#nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

#nav ul li {
	-moz-transition: margin 0.2s ease;
	-webkit-transition: margin 0.2s ease;
	-ms-transition: margin 0.2s ease;
	transition: margin 0.2s ease;
	display: inline-block;
	margin: 0 0.35em;
	padding: 0;
	vertical-align: middle;
}

#nav ul li a {
	-moz-transition: font-size 0.2s ease;
	-webkit-transition: font-size 0.2s ease;
	-ms-transition: font-size 0.2s ease;
	transition: font-size 0.2s ease;
	display: inline-block;
	height: 2.25em;
	line-height: 2.25em;
	padding: 0 1.25em;
	border: 0;
	border-radius: 8px;
	box-shadow: inset 0 0 0 1px transparent;
}

#nav ul li a:hover {
	background-color: rgba(222, 222, 222, 0.25);
}

#nav ul li a.active {
	background-color: #ffffff;
	box-shadow: none;
}

#nav.alt {
	position: fixed;
	top: 0;
	padding: 0.5em 1em;
	background-color: rgba(247, 247, 247, 0.95);
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	z-index: 10000;
}

#nav.alt ul li {
	margin: 0 0.175em;
}

#nav.alt ul li a {
	font-size: 0.9em;
}

@media screen and (max-width: 736px) {

	#nav {
		display: none;
	}

	#nav + #main {
		padding-top: 0;
	}
}

/* Dropdown Menu */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {background-color: #f1f1f1;}

.dropdown:hover .dropdown-content {display: block;}

/* Main */

	#main {
		background-color: #ffffff;
		color: #636363;
		border-radius: 0.25em;
	}

		#main input, #main select, #main textarea {
			color: #636363;
		}

		#main a:hover {
			color: #636363;
		}

		#main strong, #main b {
			color: #636363;
		}

		#main h1, #main h2, #main h3, #main h4, #main h5, #main h6 {
			color: #636363;
		}

		#main blockquote {
			border-left-color: #dddddd;
		}

		#main code {
			background: rgba(222, 222, 222, 0.25);
			border-color: #dddddd;
		}

		#main hr {
			border-bottom-color: #dddddd;
		}

		#main .box {
			border-color: #dddddd;
		}

		#main input[type="submit"],
		#main input[type="reset"],
		#main input[type="button"],
		#main button,
		#main .button {
			background-color: transparent;
			box-shadow: inset 0 0 0 1px #dddddd;
			color: #636363 !important;
		}

			#main input[type="submit"]:hover,
			#main input[type="reset"]:hover,
			#main input[type="button"]:hover,
			#main button:hover,
			#main .button:hover {
				background-color: rgba(222, 222, 222, 0.25);
			}

			#main input[type="submit"]:active,
			#main input[type="reset"]:active,
			#main input[type="button"]:active,
			#main button:active,
			#main .button:active {
				background-color: rgba(222, 222, 222, 0.5);
			}

			#main input[type="submit"].icon:before,
			#main input[type="reset"].icon:before,
			#main input[type="button"].icon:before,
			#main button.icon:before,
			#main .button.icon:before {
				color: rgba(99, 99, 99, 0.25);
			}

			#main input[type="submit"].primary,
			#main input[type="reset"].primary,
			#main input[type="button"].primary,
			#main button.primary,
			#main .button.primary {
				background-color: #8cc9f0;
				color: #ffffff !important;
				box-shadow: none;
			}

				#main input[type="submit"].primary:hover,
				#main input[type="reset"].primary:hover,
				#main input[type="button"].primary:hover,
				#main button.primary:hover,
				#main .button.primary:hover {
					background-color: #9acff2;
				}

				#main input[type="submit"].primary:active,
				#main input[type="reset"].primary:active,
				#main input[type="button"].primary:active,
				#main button.primary:active,
				#main .button.primary:active {
					background-color: #7ec3ee;
				}

				#main input[type="submit"].primary.icon:before,
				#main input[type="reset"].primary.icon:before,
				#main input[type="button"].primary.icon:before,
				#main button.primary.icon:before,
				#main .button.primary.icon:before {
					color: #ffffff !important;
				}

		#main label {
			color: #636363;
		}

		#main input[type="text"],
		#main input[type="password"],
		#main input[type="email"],
		#main select,
		#main textarea {
			background-color: rgba(222, 222, 222, 0.25);
			border-color: #dddddd;
		}

			#main input[type="text"]:focus,
			#main input[type="password"]:focus,
			#main input[type="email"]:focus,
			#main select:focus,
			#main textarea:focus {
				border-color: #8cc9f0;
				box-shadow: 0 0 0 1px #8cc9f0;
			}

		#main select {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%23dddddd' /%3E%3C/svg%3E");
		}

			#main select option {
				color: #636363;
				background: #ffffff;
			}

		#main input[type="checkbox"] + label,
		#main input[type="radio"] + label {
			color: #636363;
		}

			#main input[type="checkbox"] + label:before,
			#main input[type="radio"] + label:before {
				background: rgba(222, 222, 222, 0.25);
				border-color: #dddddd;
			}

		#main input[type="checkbox"]:checked + label:before,
		#main input[type="radio"]:checked + label:before {
			background-color: #636363;
			border-color: #636363;
			color: #ffffff;
		}

		#main input[type="checkbox"]:focus + label:before,
		#main input[type="radio"]:focus + label:before {
			border-color: #8cc9f0;
			box-shadow: 0 0 0 1px #8cc9f0;
		}

		#main ::-webkit-input-placeholder {
			color: rgba(99, 99, 99, 0.25) !important;
		}

		#main :-moz-placeholder {
			color: rgba(99, 99, 99, 0.25) !important;
		}

		#main ::-moz-placeholder {
			color: rgba(99, 99, 99, 0.25) !important;
		}

		#main :-ms-input-placeholder {
			color: rgba(99, 99, 99, 0.25) !important;
		}

		#main .formerize-placeholder {
			color: rgba(99, 99, 99, 0.25) !important;
		}

		#main .icon.major {
			border-color: #dddddd;
		}

			#main .icon.major:before {
				border-color: #dddddd;
			}

		#main .icon.alt {
			border-color: #dddddd;
			color: #636363;
		}

			#main .icon.alt:hover {
				background-color: rgba(222, 222, 222, 0.25);
			}

			#main .icon.alt:active {
				background-color: rgba(222, 222, 222, 0.5);
			}

		#main ul.alt li {
			border-top-color: #dddddd;
		}

		#main dl dt {
			color: #636363;
		}

		#main header.major h2:after {
			background-color: #dddddd;
			background-image: -moz-linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
			background-image: -webkit-linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
			background-image: -ms-linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
			background-image: linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
		}

		#main table tbody tr {
			border-color: #dddddd;
		}

			#main table tbody tr:nth-child(2n + 1) {
				background-color: rgba(222, 222, 222, 0.25);
			}

		#main table th {
			color: #636363;
		}

		#main table thead {
			border-bottom-color: #dddddd;
		}

		#main table tfoot {
			border-top-color: #dddddd;
		}

		#main table.alt tbody tr td {
			border-color: #dddddd;
		}

		#main .spotlight .image {
			border-color: #dddddd;
		}

		#main > .main {
			padding: 5em 5em 3em 5em ;
			border-top: solid 1px #dddddd;
		}

			#main > .main:first-child {
				border-top: 0;
			}

			#main > .main > .image.main:first-child {
				margin: -5em 0 5em -5em;
				width: calc(100% + 10em);
				border-top-right-radius: 0.25em;
				border-top-left-radius: 0.25em;
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}

				#main > .main > .image.main:first-child img {
					border-top-right-radius: 0.25em;
					border-top-left-radius: 0.25em;
					border-bottom-right-radius: 0;
					border-bottom-left-radius: 0;
				}

		@media screen and (max-width: 1280px) {

			#main > .main {
				padding: 4em 4em 2em 4em ;
			}

				#main > .main > .image.main:first-child {
					margin: -4em 0 4em -4em;
					width: calc(100% + 8em);
				}

		}

		@media screen and (max-width: 980px) {

			#main > .main {
				padding: 4em 3em 2em 3em ;
			}

				#main > .main > .image.main:first-child {
					margin: -4em 0 4em -3em;
					width: calc(100% + 6em);
				}

		}

		@media screen and (max-width: 736px) {

			#main > .main {
				padding: 3em 2em 1em 2em ;
			}

				#main > .main > .image.main:first-child {
					margin: -3em 0 2em -2em;
					width: calc(100% + 4em);
				}

		}

		@media screen and (max-width: 480px) {

			#main > .main {
				padding: 3em 1.5em 1em 1.5em ;
			}

				#main > .main > .image.main:first-child {
					margin: -3em 0 1.5em -1.5em;
					width: calc(100% + 3em);
				}

		}

		@media screen and (max-width: 360px) {

			#main {
				border-radius: 0;
			}

				#main > .main {
					padding: 2.5em 1em 0.5em 1em ;
				}

					#main > .main > .image.main:first-child {
						margin: -2.5em 0 1.5em -1em;
						width: calc(100% + 2em);
						border-radius: 0;
					}

						#main > .main > .image.main:first-child img {
							border-radius: 0;
						}

		}


/* Footer */

	#footer {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 5em 5em 3em 5em ;
		width: calc(100% + 2em);
		margin: 0 0 3em -2em;
	}

		#footer > * {
			width: calc(50% - 2em);
			margin-left: 2em;
		}

		#footer .copyright {
			width: 100%;
			margin: 2.5em 0 2em 0;
			font-size: 0.8em;
			text-align: center;
			color: #000;
			font-size: 0.875em;
			font-weight: 700;
		}

#footer .icon * {
    color: #808080;
}

/* Wrapper */

#wrapper {
    width: 95%;
    max-width: 94em;
    margin: 0 auto;
}

.server-configs-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.server-config {
    border-radius: 1px;
}

.server-form {
    display: flex;
    justify-content: space-between;
}

.server-form .form-row {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-grow: 1;
	background-color: rgba(255, 255, 255, 0.075);
}

.server-form .form-row input {
    flex: 1;
}

.server-form button {
    white-space: nowrap;
}

.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fab8c1;
  padding: 1em auto 1em auto;
  text-align: center;
  border-top: 1px solid #ccc;
  z-index: 1000;
  color: #818181;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cookie-consent .cookie-text {
  margin: 0;
  flex-grow: 1;
  text-align: center;
  margin: 1em auto 1em auto;
  padding: 1em auto 1em auto;
}

.cookie-consent .cookie-button {
  margin: 1em 1em 1em 1em;
  padding: 1em 1em 1em 1em;
}

.commandContainer {
    display: flex;
    flex-direction: column;
}

.commandRow {
    display: flex;
    align-items: center;
}

.commandColumn {
    padding: 5px;
    transition: flex-grow 0.3s;
}

.fixed {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100px;
}

.commandResponse {
    flex-grow: 0.1;
    flex-shrink: 1;
    flex-basis: 100px;
    transition: flex-grow 0.3s;
}

.commandRow .commandResponse:nth-child(1) {
    flex-grow: 10;
    flex-basis: calc(100% - 100px);
}

.commandRow .commandResponse.expanded {
    flex-grow: 10;
    flex-basis: calc(100% - 100px);
}

.expandButton {
    margin-left: 10px;
}

.expandButton a.button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}

.commandResponse.expanded {
    flex-grow: 10;
    flex-basis: calc(100% - 100px);
}

.commandPermsissions {
    width: 100%;
    margin: 15px 0 0 0;	
}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    padding-top: 60px;
}

.modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

#searchResults {
    white-space: pre-line;
    text-align: left;
}

.spinner {
    border: 4px solid rgba(0,0,0,.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s infinite linear;
    margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

#goHomeButton {
    display: none;
}
